import { loadDynamicScript } from './loadDynamicAssets';
// import { hasLoggedIn } from './hasLoggedIn';

const IS_DEV = process.env.GATSBY_ENVIRONMENT && process.env.GATSBY_ENVIRONMENT === 'dev';

const Hubspot = {
  didInit: false,
  pageview: function({ pathname }) {
    if (
      IS_DEV ||
      typeof window === 'undefined' ||
      ( this && !this.didInit )
    ) {
      return;
    }
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', pathname]);
    _hsq.push(['trackPageView']);
  },
  init: function() {
    if (
      IS_DEV ||
      typeof window === 'undefined' ||
      ( this && this.didInit )
    ) {
      return;
    }
    this.didInit = true;
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', window.location.pathname]);
    loadDynamicScript('id_hubspot', 'https://js.hs-scripts.com/44271427.js', () => { /* --- */ });
  }
};

export default Hubspot;
